import { useState } from 'react';
import { Image, ListGroup } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsTwitterX } from 'react-icons/bs';
import { FaFacebookSquare, FaInstagram, FaPhoneAlt, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function HeaderContainer() {

  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown4, setShowDropdown4] = useState(false);
  const [showDropdown5, setShowDropdown5] = useState(false);
  const [showDropdown6, setShowDropdown6] = useState(false);
  const [showDropdown7, setShowDropdown7] = useState(false);
  const [showDropdown8, setShowDropdown8] = useState(false);

  return (
    <>
    <div className="container-fluid top-section text-white pt-2 pb-1">
      <div>
          <Container className='d-flex flex-wrap justify-content-between align-items-center'>
              <div className="logo-top d-none d-xl-block align-items-center">
                  <div className='d-flex gap-3 flex-wrap'>
                        <div><Link to={'/'}><Image src='assets/img/logo.svg' /></Link> </div> 
                        <div>
                            <h1 className='top-head'>RED ROSE PUBLIC SCHOOL</h1>
                            <p className='top-small-head'>Bhajan Mau, Mohanlalganj, Lucknow</p>
                        </div>     
                   </div>
              </div>
              <div className="text-right d-none d-xl-block">
                  <div className='d-flex position-relative text-dark social-icons justify-content-end'>                        
                      <ListGroup.Item><Link to={'#'}><FaFacebookSquare size={'20'} className='fb-color' /></Link></ListGroup.Item>  
                      <ListGroup.Item><Link to={'#'}><FaYoutube size={'20'} className='insta-color' /></Link></ListGroup.Item>                             
                      <ListGroup.Item><Link to={'#'}><BsTwitterX size={'20'} className='tw-color' /></Link></ListGroup.Item> 
                      <ListGroup.Item><Link to={'#'}><FaInstagram size={'20'} className='youtube-color' /></Link></ListGroup.Item>                           
                  </div>   
                  <ListGroup.Item className='social-icons text-end pt-2'>
                      <Link to={'tel:+918090902463'} className='text-decoration-none'><FaPhoneAlt /> +91-809 090 2463</Link>
                  </ListGroup.Item>                   
              </div>
          </Container>
      </div>
    </div>
      <Navbar expand="lg" className="bg-body-tertiary"  sticky="top">
        <Container>
          <Navbar.Brand to={'/'} className='d-xl-none'>
              <div className="logo-top">
                   <Link to={'/'}><Image src='assets/img/logo.svg' /> </Link>      
              </div>
          </Navbar.Brand>
          <h1 className='top-head d-xl-none'>RED ROSE SCHOOL</h1>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link  as={Link} to="/">Home</Nav.Link>              
              <NavDropdown title="About Us" show={showDropdown1}  onMouseEnter={() => setShowDropdown1(true)} onMouseLeave={() => setShowDropdown1(false)} variant="secondary">
                  <NavDropdown.Item >History</NavDropdown.Item>              
                  <NavDropdown.Item as={Link} to="/About">About Red Rose</NavDropdown.Item>   
                  <NavDropdown.Item>Vision, mission and values</NavDropdown.Item>   
                  <NavDropdown.Item>Why Red Rose</NavDropdown.Item> 
                  <NavDropdown.Item>Management Message</NavDropdown.Item>
                  <NavDropdown.Item>Affiliation</NavDropdown.Item>
                  <NavDropdown.Item>Mandatory Public Disclosure</NavDropdown.Item>
                  <NavDropdown.Item>Testimonials</NavDropdown.Item>
                  <NavDropdown.Item>Branches</NavDropdown.Item>     
              </NavDropdown>
              <NavDropdown title="Admission" show={showDropdown2}  onMouseEnter={() => setShowDropdown2(true)} onMouseLeave={() => setShowDropdown2(false)} variant="secondary">
                  <NavDropdown.Item>Admission Procedure</NavDropdown.Item>              
                  <NavDropdown.Item>General Rules</NavDropdown.Item>   
                  <NavDropdown.Item>Fee Structure</NavDropdown.Item>   
                  <NavDropdown.Item>Download Prospectus</NavDropdown.Item>     
                  <NavDropdown.Item>Planner</NavDropdown.Item> 
                  <NavDropdown.Item>Safety & Security</NavDropdown.Item> 
                  <NavDropdown.Item>NCC</NavDropdown.Item>  
              </NavDropdown>
              <NavDropdown title="Academics" show={showDropdown3}  onMouseEnter={() => setShowDropdown3(true)} onMouseLeave={() => setShowDropdown3(false)} variant="secondary">
                  <NavDropdown.Item>Academic Session</NavDropdown.Item>              
                  <NavDropdown.Item>Curriculum</NavDropdown.Item>   
                  <NavDropdown.Item>Examination Rules</NavDropdown.Item>   
                  <NavDropdown.Item>Co-curricular Activities</NavDropdown.Item>    
                  <NavDropdown.Item>Discipline</NavDropdown.Item>  
                  <NavDropdown.Item>Parent Teacher meeting</NavDropdown.Item>                    
              </NavDropdown>
              <NavDropdown title="Infrastructure" show={showDropdown4}  onMouseEnter={() => setShowDropdown4(true)} onMouseLeave={() => setShowDropdown4(false)} variant="secondary">
                  <NavDropdown.Item>Classroom</NavDropdown.Item>              
                  <NavDropdown.Item>Computer Lab</NavDropdown.Item>   
                  <NavDropdown.Item>Science Lab</NavDropdown.Item>   
                  <NavDropdown.Item>Maths Lab</NavDropdown.Item> 
                  <NavDropdown.Item>Library</NavDropdown.Item> 
                  <NavDropdown.Item>Activity Room</NavDropdown.Item> 
                  <NavDropdown.Item>Music</NavDropdown.Item> 
                  <NavDropdown.Item>Digital classrooms</NavDropdown.Item> 
                  <NavDropdown.Item>Swimming Pool</NavDropdown.Item>      
              </NavDropdown>
              <NavDropdown title="Gallery" show={showDropdown5}  onMouseEnter={() => setShowDropdown5(true)} onMouseLeave={() => setShowDropdown5(false)} variant="secondary">
                  <NavDropdown.Item>Photo Album</NavDropdown.Item>              
                  <NavDropdown.Item>Photo Gallery</NavDropdown.Item>   
                  <NavDropdown.Item>Video Gallery</NavDropdown.Item>   
                  <NavDropdown.Item>Media Gallery</NavDropdown.Item>      
              </NavDropdown>
              <NavDropdown title="Uniform" show={showDropdown6}  onMouseEnter={() => setShowDropdown6(true)} onMouseLeave={() => setShowDropdown6(false)} variant="secondary">
                  <NavDropdown.Item>Summer Uniform</NavDropdown.Item>              
                  <NavDropdown.Item>Winter Uniform</NavDropdown.Item>   
                  <NavDropdown.Item>House Uniform</NavDropdown.Item>      
              </NavDropdown>
              <NavDropdown title="Students" show={showDropdown7}  onMouseEnter={() => setShowDropdown7(true)} onMouseLeave={() => setShowDropdown7(false)} variant="secondary">
                  <NavDropdown.Item>Achievements</NavDropdown.Item>              
                  <NavDropdown.Item>Toppers</NavDropdown.Item>   
                  <NavDropdown.Item>Student Council</NavDropdown.Item>   
                  <NavDropdown.Item>Competitions & Events</NavDropdown.Item> 
                  <NavDropdown.Item>Latest Updates </NavDropdown.Item>      
                  <NavDropdown.Item>Exam Schedule </NavDropdown.Item> 
                  <NavDropdown.Item>Holiday Homework </NavDropdown.Item> 
                  <NavDropdown.Item>Transfer Certificate </NavDropdown.Item> 
                  <NavDropdown.Item>Alumni Portal</NavDropdown.Item>  
              </NavDropdown>
              <Nav.Link to={'/'}>Downloads</Nav.Link>  
             
              <NavDropdown title="Enquiry" show={showDropdown8}  onMouseEnter={() => setShowDropdown8(true)} onMouseLeave={() => setShowDropdown8(false)} variant="secondary">
                  <NavDropdown.Item>Admission Enquiry</NavDropdown.Item>              
                  <NavDropdown.Item>General Enquiry</NavDropdown.Item>      
              </NavDropdown>
              <Nav.Link to={'/'}>Contact Us</Nav.Link>  
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default HeaderContainer;
