import React from 'react'
import BreadcrumbContainer from '../../Common/Breadcrumb'

const About = () => {
  return (
    <>
      <BreadcrumbContainer title="About" />

      <section className="position-relative about-section"  data-aos="fade-up">
          <div className="container">
              <div className="bg-container"  style={{backgroundImage:'url("/assets/img/bg-pattern.png")'}}></div>
              
              <div className="row align-items-center z-index-1 position-relative">
                <div className="col-lg-6 mb-1-9 mb-lg-0">
                    <div className="pe-lg-1-9 pe-xl-6">
                      <div className="section-heading half left">
                          <h2>Business Profile</h2>
                      </div>
                      <h4 className="pt-4 pb-2">Company Vision</h4>
                      <ul className="list-style-16">
                          <li>To be one of the leadingHR sourcing companypreferred by all Firmsin India.</li>
                          <li>To be one of the Leading Top Multi HR Service Providerfirmsin India</li>
                      </ul>
                      <h4 className="pt-4 pb-2">Company Mission</h4>
                      <ul className="list-style-16">
                          <li>To provide end to end solutions and services to address recruitment needs of our clients by fostering an ecosystem of partnership and commitment towards learning, innovation and process excellence.</li>
                          <li>To be perceived by our clients as a company of repute,recognized forintegrity, trust worthiness and commitment fortheir cause.</li>
                          <li>To be the choice of people by providing them an environment of learning, innovation, empowerment and wealth creation</li>
                      </ul>
                    </div>
                </div>
                <div className="col-lg-6 col-md-9 text-center">
                    <div className="row g-2">
                      <div className="col-6">
                          <img className="border-radius-5" alt="..." src="/assets/img/team-02.jpg" />
                      </div>
                      <div className="col-6">
                          <img className="border-radius-5" alt="..." src="/assets/img/team-01.jpg" />
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </section>

        <section className="parallax p-0" data-overlay-dark="0" style={{backgroundImage:'url("/assets/img/about-02.jpg")'}}>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-6 offset-lg-6">
                        <div className="about-text about-overlay">
                            <div className="w-xl-85">
                                <div className="section-heading left white"><span>about us</span>
                                    <h4>Welcome to our consulting</h4>
                                </div>
                                <div className="inner-content">
                                    <h2><span>“ Pride of the Business For Over a Hundred Years." </span> for public &amp; private clients.</h2>
                                    <p className="mb-2">perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore eritatis architecto beatae sunt explicabo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default About
