import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Layout from './Layout';
import About from './Pages/About';
const Routing = () => {
  return (
    <BrowserRouter>          
        <Routes>            
            <Route path="/" exact element={<Layout />}>
                <Route index element={<Home />} />  
                <Route path='/About' element={<About />} />                
            </Route>
        </Routes>
    </BrowserRouter>
  )
}

export default Routing
