import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

const ImportantFactContainer = () => {
    const impfact = [
        {id:1, smalldis: '4500+', dis: 'Student', img: '/assets/img/counter-icon_1.png'},
        {id:1, smalldis: '4', dis: 'Branches', img: '/assets/img/counter-icon_2.png'},
        {id:1, smalldis: '350+', dis: 'Staff', img: '/assets/img/counter-icon_3.png'},
        {id:1, smalldis: '100+', dis: 'Almuni Review', img: '/assets/img/counter-icon_4.png'},
    ];  
  return (
    <>
      <Container fluid  className='pt-4 pb-5 bg-dark-grey' data-aos="fade-up">
            <Row>
                <Col>
                    <Container className="almuni-section">
                        <Row>
                            <Col>
                                <h2 className='our-topers liner-text2 mt-5'>Important Facts</h2>
                            </Col>
                        </Row>
                        <Row className='pt-5'>
                        {impfact.map(item => (<Col className='col-md-3 pb-4'>
                                <div className='fals-section d-flex gap-3 align-items-center'>
                                    <div>
                                        <Image src={item.img} />
                                    </div>
                                    <div>
                                        <h2 className='f-28 font-weight-bold mb-0'>
                                            {item.smalldis}
                                        </h2>
                                        <p>{item.dis}</p>
                                    </div>
                                </div>
                            </Col>
                            ))}  
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>

    </>
  )
}

export default ImportantFactContainer
